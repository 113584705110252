import { Discount } from "~/utils/enum/Discount";
import { hasHigherDiscount } from "~/utils/hasHigherDiscount";

export function getDiscountForDepartment(departmentTitle?: string): number {
  const title = getUniformString(departmentTitle);

  if (hasHigherDiscount(title)) {
    return Discount.SPECIAL;
  }

  return Discount.DEFAULT;
}

function getUniformString(value?: string): string | undefined {
  if (value === undefined) {
    return;
  }

  return value.trim().toLowerCase();
}
